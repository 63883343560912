<template>
  <v-container class="d-flex flex-column">
    <v-card v-if="showForm" flat>
      <CategoryTitle :category="category" class="mt-0 mb-6 my-lg-6" />

      <v-row no-gutters class="mt-4" v-if="!showNewPasswordPanel">
        <v-card-title class="px-0">
          Inserisci il codice che hai ricevuto via mail o via
          messaggio</v-card-title
        >
        <div class="mt-2 mb-7">
          <ResponseMessage :response="message" />
        </div>
        <v-col cols="12" sm="8">
          <v-card-text class="pa-0">
            <v-form
              v-on:submit.prevent
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                v-model="token"
                label="Codice di sicurezza *"
                required
                outlined
                dense
                clearable
                @keyup.enter="checkToken"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4" class="px-0 px-sm-4">
          <v-card-actions class="pa-0">
            <v-btn
              @click="checkToken"
              class="reset-button"
              color="primary"
              large
              :block="$vuetify.breakpoint.xs"
              min-width="200"
              height="40"
              depressed
              :loading="loading"
              :disabled="!valid || token.length <= 0"
              >{{ $t("login.submitResetPassword") }}</v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>

      <div class="mt-4" v-else>
        <v-card-title class="px-0">Inserisci la nuova passoword</v-card-title>
        <v-card-text class="pa-0">
          <v-form
            v-on:submit.prevent
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="password"
                  :label="$t('profile.newPassword') + ' *'"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  @change="validate"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="passwordConfirm"
                  :label="$t('profile.passwordConfirm') + ' *'"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordConfirmRules"
                  @click:append="toggleShowPassword"
                  required
                  outlined
                  dense
                  @change="validate"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-btn
            @click="handleResetPasswordConfirm"
            class="reset-button"
            color="primary"
            large
            :block="$vuetify.breakpoint.xs"
            min-width="200"
            height="40"
            depressed
            :loading="loading"
            >{{ $t("login.submitResetPassword") }}</v-btn
          >
        </v-card-actions>
      </div>

      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";

import category from "~/mixins/category";

import RegistrationService from "~/service/userService";
import userService from "~/service/userService";

import { requiredValue } from "~/validator/validationRules";
import { passwordRule } from "@/customValidationRules";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordConfirm",
  mixins: [category],
  data() {
    return {
      token: "",
      valid: true,
      loading: false,
      lazy: true,
      response: {},
      showForm: true,
      showNewPasswordPanel: false,
      password: null,
      passwordConfirm: null,
      showPassword: false,
      passwordRules: [requiredValue("Digitare la password"), passwordRule()],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ]
    };
  },
  props: {
    message: { type: Object, default: () => {} },
    login: { type: String, default: undefined },
    queryToken: { type: String, default: undefined }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async checkToken(token) {
      let _this = this;
      try {
        _this.loading = true;
        let res = await userService.resetPasswordTokenCheck(
          undefined,
          token || _this.token,
          _this.login
        );

        if (res.response?.status == 0) {
          this.showNewPasswordPanel = true;
          if (token) {
            this.token = token;
          }
          _this.response = res;
        } else {
          this.showNewPasswordPanel = false;
          _this.response = res;
        }
      } catch (err) {
        this.showNewPasswordPanel = false;
        if (err.errors) _this.response = err;
      } finally {
        this.loading = false;
      }
    },
    async handleResetPasswordConfirm(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.valid) {
        _this.loading = true;
        let res = null;
        try {
          res = await RegistrationService.resetPassword(
            undefined,
            _this.token,
            _this.password,
            _this.login
          );
          if (res.response?.status == 0) {
            _this.$router.push({ name: "ResetPasswordSent" });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          if (err.errors) {
            _this.response = err;
          } else {
            _this.response = err.response.data.response;
          }
        } finally {
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  },
  mounted() {
    if (this.queryToken && this.login) {
      this.checkToken(this.queryToken);
    }
  }
};
</script>
